import '../App.css';
import plakat from '../images/plakats/2024.jpg'

function Home() {
  return (
    <div className="Home">
      <main>
            <h1>Letní dětský tábor Rozmahelová</h1>

            <p className='taborName'>BOROVINA 2025</p>

            <img src={plakat} width={100 + "%"}/>

            <iframe width="100%" height="480" src="https://www.youtube.com/embed/0f426gxWTls" title="Letní Tábor Rozmahelová" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        </main>
    </div>
  );
}

export default Home;